import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, Paragph } from "@components/layout/style"
import { ListDisc, MainContainer, RightContainer, StyledHero } from "../../components/oferta.style"


const Ortodoncja:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
    canonicalUrl: 'https://www.mediroot.pl/oferta/sedacja-wziewna/',
      title: 'Sedacja wziewna | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>
        <H1>
          Sedacja wziewna
        </H1>

        <Paragph>

        Dla dużej grupy pacjentów, szczególnie dzieci, ale również i dorosłych, wizyta w gabinecie stomatologicznym może łączyć się z poczuciem stresu i lęku. U niektórych osób przybiera ono formę paraliżującego strachu zwanego dentofobią.

        Często osoby takie krążą od gabinetu do gabinetu, ale nikt nie jest w stanie im pomóc. Gaz rozweselający jest naszą odpowiedzią na problemy tych Pacjentów.
        Odczucia podczas wdychania gazu rozweselającego to głębokie odprężenie, euforia oraz poczucie oderwania od rzeczywistości. Tak przyjemne wrażenia sprawiają, iż Pacjent nie zwraca uwagi na wykonywany w międzyczasie zabieg. Dodatkowo gaz rozweselający zwiększa odporność na ból.
        Dorośli często opisują to uczucie jako unoszenie się, latanie, dryfowanie na wodzie. Dzieci zaś opowiadają, że były w ulubionej bajce, na ciekawej wycieczce lub latały w chmurach.
        Stan tak głębokiego odprężenia uzyskiwany jest w czasie wdychania przez maseczkę, umieszczoną na nosie, mieszaniny podtlenku azotu i tlenu. Pacjent jednocześnie zachowuje świadomości i możliwość komunikacji.
        Gaz rozweselający polecany jest zarówno u dzieci jak i u dorosłych w sytuacji:
        </Paragph>
        <ListDisc>
          <li>
            silnego lęku przed jakimkolwiek leczeniem,
          </li>
          <li>
            silnego lęku przed ukłuciem
          </li>
          <li>
            silny odruch wymiotny.
          </li>
          <li>
            nadpobudliwość nerwowa.
          </li>
          <li>
            długie, monotonne lub szczególnie nieprzyjemne zabiegi chirurgiczne, protetyczne, wyciski oraz zdjęcia RTG u osób z silnym odruchem wymiotnym.
          </li>
          <li>
            usuwanie złogów nazębnych.
          </li>
          <li>
            otwieranie zęba ze zgorzelinowym rozpadem miazgi
          </li>
          <li>
            opracowywanie próchnicy.
          </li>
          <li>
            oraz w stanach chorobowych takich jak: np. astma, padaczka itp.
          </li>
        </ListDisc>

        <Paragph>
          Przeciwwskazania
        </Paragph>
        <ListDisc>
          <li>
            Przeziębienie, przerost migdałków gardłowych – niedrożności górnych dróg oddechowych.
          </li>
          <li>
            I trymestr ciąży
          </li>
          <li>
            Choroby psychiczne – groźba pogłębienia stanu choroby- działanie halucynogenne N2O
          </li>
          <li>
            Stwardnienie rozsiane, porfiria, miastenia gravis – działanie neurotoksyczne.
          </li>
          <li>
            Rozstrzenie oskrzeli, rozedma płuc – ośrodek oddechowy u chorych pacjentów reaguje na stężenie tlenu we krwi.
          </li>
          <li>
            Alkoholizm (zwłaszcza wyleczony – po zabiegu może nastąpić powrót do nałogu)
          </li>
          <li>
            Brak współpracy pacjenta – nie wolno zmuszać do założenia maseczki na nos.
          </li>
        </ListDisc>

        <Paragph>
        Zalecenia przed podaniem „gazu rozweselającego”
        </Paragph>
        <ListDisc>
          <li>
          Pacjent powinien bezwzględnie przestrzegać minimum  od 2 do 3 godz. powstrzymywania się od przyjmowania pokarmów
          </li>
          <li>
          Dzieci muszą przychodzić z dorosłą osobą towarzyszącą (opiekunem prawnym)
          </li>
          <li>
          Nienależny zakładać zbyt obcisłych ubrań.
          </li>
          <li>
          Nienależny pić alkoholu w dniu wizyty
          </li>
        </ListDisc>

      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default Ortodoncja;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
